import { ContentTypeLink } from 'utility/utility'
import { TopicLinkFragment } from 'generated/graphql'
import { Link } from 'components/shared/Link'

import { TopicUrl } from 'content-types/Topic/Topic.Url/Topic.Url'

type TopicLinkProps = TopicLinkFragment & { linkOverrideLabel?: string | null }
export const TopicLink: ContentTypeLink<TopicLinkProps> =
    ({
        children,
        className,
        title,
        seo,
        ...topic
    }) =>
        <Link
            href={TopicUrl(topic)}
            className={className}
            title={ topic.linkOverrideLabel ?? seo?.metaTitle ?? title }
            prefetch={false}
            siteContext={topic.siteContext}
        >
            {children}
        </Link>
