'use client'

import { FormObjectView } from 'utility/utility'
import { ComponentFormCaptureFieldFragment } from 'generated/graphql'
import { useFormContext } from 'react-hook-form'

export const ComponentFormCaptureField: FormObjectView<ComponentFormCaptureFieldFragment> =
    ({
        name
    }) => {

        const { register } = useFormContext()

        return (
            <input
                type='hidden'
                value={undefined}
                {...register(name)}
            />
        )

    }
