import { ContentTypeLink } from 'utility/utility'
import { PolymerDemandLinkFragment } from 'generated/graphql'
import { Link } from 'components/shared/Link'

import { PolymerDemandUrl } from 'content-types/PolymerDemand/PolymerDemand.Url/PolymerDemand.Url'

export const PolymerDemandLink: ContentTypeLink<PolymerDemandLinkFragment> =
    ({
        children,
        className,
        title,
        seo,
        ...polymerDemand
    }) =>
        <Link
            href={PolymerDemandUrl(polymerDemand)}
            className={className}
            title={ seo?.metaTitle ?? title }
            prefetch={false}
            siteContext={polymerDemand.siteContext}
        >
            {children}
        </Link>
