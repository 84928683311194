import { ContentTypeLink } from 'utility/utility'
import { PortfolioLinkFragment } from 'generated/graphql'
import { Link } from 'components/shared/Link'

import { PortfolioUrl } from 'content-types/Portfolio/Portfolio.Url/Portfolio.Url'

export const PortfolioLink: ContentTypeLink<PortfolioLinkFragment> =
    ({
        children,
        className,
        title,
        seo,
        ...portfolio
    }) =>
        <Link
            href={PortfolioUrl(portfolio)}
            className={className}
            title={ seo?.metaTitle ?? title }
            prefetch={false}
            siteContext={portfolio.siteContext}
        >
            {children}
        </Link>
