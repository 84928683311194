import { ContentTypeLink } from 'utility/utility'
import { DatabaseLinkFragment } from 'generated/graphql'
import { Link } from 'components/shared/Link'

import { DatabaseUrl } from 'content-types/Database/Database.Url/Database.Url'

export const DatabaseLink: ContentTypeLink<DatabaseLinkFragment> =
    ({
        children,
        className,
        title,
        seo,
        ...database
    }) =>
        <Link
            href={DatabaseUrl(database)}
            className={className}
            title={ seo?.metaTitle ?? title }
            prefetch={false}
            siteContext={database.siteContext}
        >
            {children}
        </Link>
