import { FC } from 'react'
import { strapiPublicImage } from 'utility/utility'
import { Media } from 'content-types/UploadFile/UploadFile'

export const UploadFileVideo: FC<Media> = ({
    url,
    mime,
    className,
    style
}) =>
    <div>
        <video
            className={className}
            autoPlay={true}
            muted={true}
            loop={true}
            style={style}
            playsInline={true}
        >
            <source src={strapiPublicImage(url)} type={mime}/>
        </video>
    </div>
