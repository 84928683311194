import { ContentTypeLink } from 'utility/utility'
import { ArticleLinkFragment } from 'generated/graphql'
import { Link } from 'components/shared/Link'

import { ArticleUrl } from 'content-types/Article/Article.Url/Article.Url'

export const ArticleLink: ContentTypeLink<ArticleLinkFragment> =
    ({
        children,
        className,
        title,
        ...article
    }) =>
        <Link
            href={ArticleUrl(article)}
            className={className}
            title={title}
            prefetch={false}
            siteContext={article.siteContext}
        >
            {children}
        </Link>
