const protocols = [

    'http',
    'https',
    'mailto',
    'tel',

    /* Content Types */
    'activity',
    'article',
    'channel',
    'database',
    'event-series',
    'event-page',
    'home',
    'insight-report',
    'magazine',
    'magazine-page',
    'market-report',
    'page',
    'polymer-demand',
    'portfolio',
    'topic',

    /* Special UI elements */
    'login',
    'form',

    /* Document types */
    'attendee-list',
    'brochure',
    'media-kit',
    'newsletter-issue',
    'presentation-pack',
    'press-release',
    'proposal',
    'white-paper'

]

export function uriTransformer(uri: string): string {

  const url = (uri || '').trim()
  const first = url.charAt(0)

  if (first === '#' || first === '/') {
    return url
  }

  const colon = url.indexOf(':')
  if (colon === -1) {
    return url
  }

  let index = -1

  while (++index < protocols.length) {

    const protocol = protocols[index]

    if (
      colon === protocol?.length &&
      url.slice(0, protocol.length).toLowerCase() === protocol
    ) {
      return url
    }
  }

  index = url.indexOf('?')
  if (index !== -1 && colon > index) {
    return url
  }

  index = url.indexOf('#')
  if (index !== -1 && colon > index) {
    return url
  }

  // eslint-disable-next-line no-script-url
  return 'javascript:void(0)'

}
