
import { ContentTypeLink } from 'utility/utility'
import { PageIdLinkFragment } from 'generated/graphql'
import { type PageContext } from 'context/PageContext'
import { PageLink } from 'content-types/Page/Page.Link/Page.Link'

export const PageIdLink: ContentTypeLink<PageIdLinkFragment & {
    path: string,
    pageContext: PageContext
}> =
    ({
        path,
        siteContext,
        pageContext,
        ...page
    }) => {

        const { allPages } = pageContext

        const target = allPages?.data.find( p => p.id === path )

        return (
            target?.attributes?.slug === undefined ? <span>{page.children}</span> :
                <PageLink
                    siteContext={siteContext}
                    {...{...page,...target.attributes}}
                />
        )

    }
