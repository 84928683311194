import { ContentTypeLink } from 'utility/utility'
import { MarketReportLinkFragment } from 'generated/graphql'
import { Link } from 'components/shared/Link'

import { MarketReportUrl } from 'content-types/MarketReport/MarketReport.Url/MarketReport.Url'

export const MarketReportLink: ContentTypeLink<MarketReportLinkFragment> =
    ({
        children,
        className,
        title,
        seo,
        ...marketReport
    }) =>
        <Link
            href={MarketReportUrl(marketReport)}
            className={className}
            title={ seo?.metaTitle ?? title }
            prefetch={false}
            siteContext={marketReport.siteContext}
        >
            {children}
        </Link>
