/* eslint-disable */

import { Children, memo, type DetailedHTMLProps, type AnchorHTMLAttributes } from 'react'
import { Link } from 'components/shared/Link'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import { uriTransformer } from 'components/shared/Markdown/uriTransformer'

import { ContentTypeView, strapiPublicImage } from 'utility/utility'

import { ActivityLink } from 'content-types/Activity/Activity.Link/Activity.Link'

import { ArticleLink } from 'content-types/Article/Article.Link/Article.Link'
import { DatabaseLink } from 'content-types/Database/Database.Link/Database.Link'
import { EventSeriesLink } from 'content-types/EventSeries/EventSeries.Link/EventSeries.Link'
import { FormModal } from 'content-types/Form/Form.Modal/Form.Modal'
import { HomeLink } from 'content-types/Home/Home.Link/Home.Link'
import { InsightReportLink } from 'content-types/InsightReport/InsightReport.Link/InsightReport.Link'
import { MagazineLink } from 'content-types/Magazine/Magazine.Link/Magazine.Link'
import { MarketReportLink } from 'content-types/MarketReport/MarketReport.Link/MarketReport.Link'
import { PageIdLink } from 'content-types/Page/Page.IdLink/Page.IdLink'
import { MagazinePageIdLink } from 'content-types/MagazinePage/MagazinePage.IdLink/MagazinePage.IdLink'
import { EventSeriesPageIdLink } from 'content-types/EventSeriesPage/EventSeriesPage.IdLink/EventSeriesPage.IdLink'
import { PolymerDemandLink } from 'content-types/PolymerDemand/PolymerDemand.Link/PolymerDemand.Link'
import { PortfolioLink } from 'content-types/Portfolio/Portfolio.Link/Portfolio.Link'
import { SocialMediaChannelLink } from 'content-types/SocialMediaChannel/SocialMediaChannel.Link/SocialMediaChannel.Link'
import { TopicLink } from 'content-types/Topic/Topic.Link/Topic.Link'
import { ComponentBodyLoginButton } from 'components/body/ComponentBodyLoginButton/ComponentBodyLoginButton'

// Note: Type is a hack
const MarkdownLink: ContentTypeView<Omit<DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>, "ref">> =
    memo(({href, className, children, title, pageContext, siteContext, articleContext}): JSX.Element => {

        const { allForms, allChannels } = pageContext

        if ( href === undefined || href === '' ) {
            return <a className={className}>{children}</a>
        }

        const [scheme,path=''] = href.split(':')
        
        let [slug, queryParameters, hash, extras]: Array<string|undefined> = []
        if (path.includes("#")) {
            [slug, extras=''] = path.split('#')
            if (path.includes("?")) {
                [hash,queryParameters] = extras.split('?')
            } else {
                hash = extras
            }
        } else if (path.includes("?")) {
            [slug,queryParameters] = path.split('?')
        } else {
            slug = path
        }

        switch ( scheme ) {

            case 'activity':
                return slug === undefined ? <span>children</span> :
                    <ActivityLink
                        key={href}
                        slug={slug}
                        title={title ?? ''}
                        queryParameters={queryParameters}
                        hash={hash}
                        siteContext={siteContext}
                    >
                        {children}
                    </ActivityLink>

            case 'article':
                return slug === undefined ? <span>children</span> :
                    <ArticleLink
                        key={href}
                        slug={slug}
                        title={title ?? ''}
                        queryParameters={queryParameters}
                        hash={hash}
                        siteContext={siteContext}
                    >
                        {children}
                    </ArticleLink>

            case 'database':
                return slug === undefined ? <span>children</span> :
                    <DatabaseLink
                        key={href}
                        slug={slug}
                        title={title ?? ''}
                        queryParameters={queryParameters}
                        hash={hash}
                        siteContext={siteContext}
                    >
                        {children}
                    </DatabaseLink>

            case 'event-series':
                return slug === undefined ? <span>children</span> :
                    <EventSeriesLink
                        key={href}
                        slug={slug}
                        title={title ?? ''}
                        queryParameters={queryParameters}
                        hash={hash}
                        siteContext={siteContext}
                    >
                        {children}
                    </EventSeriesLink>

            case 'event-page':
                return slug === undefined ? <span>children</span> :
                    <EventSeriesPageIdLink
                        key={href}
                        path={slug}
                        title={title ?? ''}
                        queryParameters={queryParameters}
                        hash={hash}
                        siteContext={siteContext}
                        pageContext={pageContext}
                    >
                        {children}
                    </EventSeriesPageIdLink>

            case 'home':
                return slug === undefined ? <span>children</span> :
                    <HomeLink
                        key={href}
                        slug={slug}
                        title={title ?? ''}
                        queryParameters={queryParameters}
                        hash={hash}
                        siteContext={siteContext}
                    >
                        {children}
                    </HomeLink>

            case 'insight-report':
                return slug === undefined ? <span>children</span> :
                    <InsightReportLink
                        key={href}
                        slug={slug}
                        title={title ?? ''}
                        queryParameters={queryParameters}
                        hash={hash}
                        siteContext={siteContext}
                    >
                        {children}
                    </InsightReportLink>

            case 'magazine':
               return slug === undefined ? <span>children</span> :
                    <MagazineLink
                        key={href}
                        slug={slug}
                        title={title ?? ''}
                        queryParameters={queryParameters}
                        hash={hash}
                        siteContext={siteContext}
                    >
                        {children}
                    </MagazineLink>

            case 'magazine-page':
                return slug === undefined ? <span>children</span> :
                    <MagazinePageIdLink
                        key={href}
                        path={slug}
                        title={title ?? ''}
                        queryParameters={queryParameters}
                        hash={hash}
                        siteContext={siteContext}
                        pageContext={pageContext}
                    >
                        {children}
                    </MagazinePageIdLink>

            case 'market-report':
                return slug === undefined ? <span>children</span> :
                    <MarketReportLink
                        key={href}
                        slug={slug}
                        title={title ?? ''}
                        queryParameters={queryParameters}
                        hash={hash}
                        siteContext={siteContext}
                    >
                        {children}
                    </MarketReportLink>

            case 'page':
                return slug === undefined ? <span>children</span> :
                    <PageIdLink
                        key={href}
                        path={slug}
                        title={title ?? ''}
                        queryParameters={queryParameters}
                        hash={hash}
                        siteContext={siteContext}
                        pageContext={pageContext}
                    >
                        {children}
                    </PageIdLink>

            case 'portfolio':
                return slug === undefined ? <span>children</span> :
                    <PortfolioLink
                        key={href}
                        slug={slug}
                        title={title ?? ''}
                        queryParameters={queryParameters}
                        hash={hash}
                        siteContext={siteContext}
                    >
                        {children}
                    </PortfolioLink>

            case 'polymer-demand':
                return slug === undefined ? <span>children</span> :
                    <PolymerDemandLink
                        key={href}
                        title={title ?? ''}
                        queryParameters={queryParameters}
                        hash={hash}
                        siteContext={siteContext}
                    >
                        {children}
                    </PolymerDemandLink>

            case 'topic':
                return slug === undefined ? <span>children</span> :
                    <TopicLink
                        key={href}
                        slug={slug}
                        title={title ?? ''}
                        queryParameters={queryParameters}
                        hash={hash}
                        siteContext={siteContext}
                    >
                        {children}
                    </TopicLink>

            case 'channel': {

                const channelEntity = allChannels?.data.find( ({id}) => id === slug)
                const channel = channelEntity?.attributes

                return channel === undefined || channel === null ? <></> :
                    <SocialMediaChannelLink
                        key={href}
                        {...channel}
                        queryParameters={queryParameters}
                        siteContext={siteContext}
                    />

            }

            case 'login':
                return  <ComponentBodyLoginButton
                            key={href}
                            __typename={'ComponentBodyLoginButton'}
                            siteContext={siteContext}
                            pageContext={pageContext}
                            articleContext={articleContext}
                            hidden={null} id={''} linkText={null}
                            sectionBackground={null}
                        />

            case 'form': {

                const formEntity = allForms?.data.find( ({id}) => id === slug )
                const form = formEntity?.attributes

                return formEntity === undefined || formEntity.id === null || form === undefined || form === null ? <></> :
                    <FormModal
                        key={href}
                        id={formEntity.id}
                        siteContext={siteContext}
                        pageContext={pageContext}
                        articleContext={articleContext}
                        {...form}
                    >
                        {children}
                    </FormModal>

            }

            case 'attendee-list':
            case 'brochure':
            case 'media-kit':
            case 'newsletter-issue':
            case 'presentation-pack':
            case 'press-release':
            case 'proposal':
            case 'white-paper':
                return  <Link
                            key={href}
                            href={new URL(`/api/document/${scheme}/${slug}`,siteContext.siteUrl)}
                            className={className}
                            target='_blank'
                            prefetch={false}
                            siteContext={siteContext}
                        >
                            {children}
                        </Link>

            case 'mailto':
                return <a
                            key={href}
                            href={href}
                            className={className}
                        >
                            {children}
                        </a>

            default:
                return  href.startsWith('http') ?
                            <a
                                key={href}
                                href={href}
                                className={className}
                                target='_blank'
                            >
                                {children}
                            </a>
                        :
                            <Link
                                key={href}
                                href={new URL(href,siteContext.siteUrl)}
                                className={className}
                                replace={ href.substring(0,1) === '#' }
                                scroll={ href.substring(0,1) !== '#' }
                                siteContext={siteContext}
                            >
                                {children}
                            </Link>

        }
    })

// Note: type is a hack
export const Markdown: ContentTypeView<{children: string}> =
    memo(({
        siteContext,
        pageContext,
        articleContext,
        children
    }) => {

        return (
            <ReactMarkdown
                key='markdown'
                rehypePlugins={[rehypeRaw]}
                urlTransform={uriTransformer}
                components={{
                    p: (props): JSX.Element => {
                        if ( Children.toArray(props?.children).length === 1 ) {

                            const element = Children.toArray(props.children)[0] as JSX.Element | undefined | null

                            if (
                                element !== null
                                && element !== undefined
                                && element.props !== undefined
                                && element.props['href']
                                && ( element.props.href as string ).startsWith('form:')
                            ) {
                                return <MarkdownLink {...(element.props)} pageContext={pageContext}/>
                            }
                            else {
                                return <p {...props}/>
                            }

                        }
                        else {
                            return <p {...props}/>
                        }
                    },
                    a: (props) =>
                        <MarkdownLink
                            {...props}
                            siteContext={siteContext}
                            pageContext={pageContext}
                            articleContext={articleContext}
                        />,
                    img: ({src,alt,...img}) =>
                        <picture>
                            <img
                                {...img}
                                src={ src === undefined ? '': strapiPublicImage(src) }
                                alt={ alt === undefined ? '' : alt }
                            />
                        </picture>
                }}
            >{children}</ReactMarkdown>
        )

    })


