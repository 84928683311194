'use client'

import { FormObjectView } from 'utility/utility'
import { ComponentFormHiddenFieldFragment } from 'generated/graphql'
import { useFormContext } from 'react-hook-form'

export const ComponentFormHiddenField: FormObjectView<ComponentFormHiddenFieldFragment> =
    ({
        name,
        value
    }) => {

        const { register } = useFormContext()

        return (
            <input
                type='hidden'
                value={value}
                {...register(name)}
            />
        )

    }
