import { FC } from 'react'
import { UploadFileImage } from 'content-types/UploadFile/UploadFile.Image/UploadFile.Image'
import { UploadFileSvg } from 'content-types/UploadFile/UploadFile.Svg/UploadFile.Svg'
import { UploadFileVideo } from 'content-types/UploadFile/UploadFile.Video/UploadFile.Video'
import { type Media } from 'content-types/UploadFile/UploadFile'

export const UploadFileMedia: FC<Media & {embedSVG: boolean, loading?: 'lazy' | 'eager'}> = ({embedSVG=true,...media}) => {
    if ( media.mime === 'image/svg+xml' && embedSVG ) {
        return <UploadFileSvg {...media} />
    }
    else if ( media.mime === 'video/mp4' ) {
        return <UploadFileVideo {...media} />
    }
    else {
        return <UploadFileImage {...media} />
    }
}
