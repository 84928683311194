import { ContentTypeLink } from 'utility/utility'
import { InsightReportLinkFragment } from 'generated/graphql'
import { Link } from 'components/shared/Link'

import { InsightReportUrl } from 'content-types/InsightReport/InsightReport.Url/InsightReport.Url'

export const InsightReportLink: ContentTypeLink<InsightReportLinkFragment> =
    ({
        children,
        className,
        title,
        seo,
        ...insightReport
    }) =>
        <Link
            href={InsightReportUrl(insightReport)}
            className={className}
            title={ seo?.metaTitle ?? title }
            prefetch={false}
            siteContext={insightReport.siteContext}
        >
            {children}
        </Link>
